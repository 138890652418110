import React from "react"
import { Button, Icon } from 'semantic-ui-react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/tooltip.css"
import "./pricing.css"

const PlansPage = () => (

  <Layout>
    <SEO title="Features & plans" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <article className="slim pricing">
        <h1>Plans</h1>
        <br/>

        <div className="ui centered middle aligned stackable grid container">
          <div className="ui two column row">
            <div className="column">
              <div className="ui fluid card prime-card">
                <div className="center aligned content">
                  <div className="header"><h2>Prime</h2></div>
                  <div className="description">
                    <p>End-to-end verifiability, voter privacy, priority support.</p>
                    <br/>
                  </div>
                </div>
                <div className="center aligned extra content">
                  <a href="https://nvotes.com/contact/">
                    <Button animated="fade" color="blue" size="huge">
                      <Button.Content visible>Contact us for pricing</Button.Content>
                      <Button.Content hidden>
                        Contact us for pricing &nbsp;
                        <Icon name='arrow right' />
                      </Button.Content>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="ui fluid card">
                <div className="center aligned content">
                  <div className="header"><h2>Enterprise</h2></div>
                  <div className="description">
                    <p>Fully customizable. High risk, high exposure scenarios.</p>
                    <br/>
                  </div>
                </div>
                <div className="center aligned extra content">
                  <a href="https://nvotes.com/contact/">
                    <Button animated="fade" basic color="blue" size="huge">
                      <Button.Content visible>Contact us for pricing</Button.Content>
                      <Button.Content hidden>
                        Contact us for pricing &nbsp;
                        <Icon name='arrow right' />
                      </Button.Content>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2>Compare Plans</h2>

        <div className="sample-container" >

        <table className="ui unstackable table plans-table">
        <thead>
        <tr>
        <th>Features</th>
        <th>Prime</th>
        <th>Enterprise</th>
        </tr>
        </thead>
        <tbody>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Pricing</th>
        </tr>
        <tr>
        <td>
          <div className="ui icon button" data-tooltip="Preparations before carrying out an election require the contract to be signed an adequate time period before the election starts. Note that this requirement only applies to the Enterprise plan; you can run an election in minutes with the Basic plan." data-position="right center" data-inverted=""> i </div>
          Days in advance needed for a signed contract
        </td>
        <td className="center aligned"><span className="yes">n/a</span></td>
        <td className="center aligned"><span className="yes">30 days</span></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Before the election starts the billing of the total price needs to have been completed. We support payment through Paypal or bank transfer; contact us if you need any other payment procedure. Note that the Basic plan features automatic credit card payment; again, contact us if you need any other payment procedure." data-position="right center" data-inverted=""> i </div>Dedicated deployment setup fee</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Our engineers will deploy a dedicated environment with two powerful backend servers and a load balancer, connected to two separate election authority servers, and make sure that the deployment is fully working. In addition, the Managed plan uses more powerful machines and replicates this environment in a pre-production environment where tests can be done before moving any change into production." data-position="right center" data-inverted=""> i </div>Dedicated deployment setup fee</td>
        <td><span className="yes"></span></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Authentication</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Send a personalized email message to the electorate with a different One-Time-Password (OTP) code to each one of them. Email authentication provides good balance between easy of use and security for many organizations where the census is undisputed and verified. The pricing per eligible voter includes sending one message per each, extra messages incur extra charges." data-position="right center" data-inverted=""> i </div>Email One-Time-Password (OTP) authentication method</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Organizer-provided password authentication</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Send a personalized SMS message to the electorate with a different One-Time-Password (OTP) code to each one of them. Almost all international mobile phone number prefixes are supported. We use a highly reliable SMS relay server. The SMS authentication vector allows you to verify that the voter has access to the associated mobile phone number, and in some cases this provides the best balance between security and easy of use for nation-wide open census registration or if the eligibility of the telephone number is undisputed and previously verified. The pricing per eligible voter includes sending one message per each, extra messages incur extra charges." data-position="right center" data-inverted=""> i </div>SMS One-Time-Password (OTP) authentication method</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Dedicated deployment in the Enterprise plan allows us to configure any supported authentication method for you." data-position="right center" data-inverted=""> i </div>Configurable election organizer authentication method</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Support multiple authentication mechanisms at once, so that the voter can choose which one to use." data-position="right center" data-inverted=""> i </div>Multiple authentication mechanisms</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>LDAP authentication</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Send credentials via snail mail. This method is ideal for public elections: it allows end-to-end verifiability without trusting the voting booth machine. It usually notably increases participation because it's a method that makes sure that all voters receive information about the election, thus maintaining a high level of equality of opportunity with respect to the right to vote. On the other hand it is more expensive than other authentication methods. Contact us if you want to use this authentication method." data-position="right center" data-inverted=""> i </div>Postal mail One-Time-Password (OTP) authentication method</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Digital certificates and electronic identification cards are the most secure authentication methods to date. They provide strong guarantees about the identity of the voter. In many scenarios they are not available nor easy to use, but when they are an option they are usually the best authentication method. Many digital certificates have digital signing support, which can be used to sign encrypted ballots improving dispute-freeness and verifiability in the election." data-position="right center" data-inverted=""> i </div>Digital certificates / Electronic-Id</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Two factor authentication provides an extra authentication step for improved security. It typically requires something you know and something you have. For example, a two factor authentication method would be to first ask for your password (something you know) and then for a code generated in an application installed in your mobile phone (something you have). Even more secure methods involve the usage of hardware tokens. Two factor authentication might be a bit cumbersome for many voters, but needed for high risk elections and it's always recommended to use for election organizers. In some cases, a third authentication factor is used. Please contact us if you need multi-factor authentication." data-position="right center" data-inverted=""> i </div>Two factor authentication methods</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Free and Premium plan do not allow integration with external authorization APIs, but the dedicated deployment in the Enterprise plan allows us to integrate an external authorization API. This is useful for example to integrate census verification against your external census database. This requires additional setup and software development, so please contact us if you need this feature." data-position="right center" data-inverted=""> i </div>Integration with external authorization APIs</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Privacy</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Not even the administrators of the electronic ballot box can know the content of your vote, because the ballot is encrypted in your web browser." data-position="right center" data-inverted=""> i </div>Ballot encrypted with javascript in the web browser</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Not even administrators can know the content of your vote, because the ballot is encrypted in your web browser with a key generated by multiple independent election authorities." data-position="right center" data-inverted=""> i </div>Vote privacy ensured by independent election authorities</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Some clients require servers to be hosted in a country or with a trusted provider. This option is available in the Enterprise plan. Free and premium plans use Amazon web servers located in Europe." data-position="right center" data-inverted=""> i </div>Deployment in your premises or with specific servers provider</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Census</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="When the census of the election is known before the election starts it's called a closed census. This means that new electors cannot register and enter the census during the voting period. Closing the census before starting the election is recommended for security reasons, as it allows you to verify the census data beforehand. Our registry management backoffice is very powerful and allows your staff to manage any census issue you might encounter during the voting period." data-position="right center" data-inverted=""> i </div>Closed census registry</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Our registry management backoffice allows you to configure custom the registration and authentication fields associated with each voter. These are the fields that voters have to fill during open registration." data-position="right center" data-inverted=""> i </div>Custom census registration fields</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="When you allow new and previously unauthorized voters to register during the voting period. Because this is a higher security risk it is only available in the Enterprise plan. Our registry management backoffice is very powerful and allows your staff to manage any census issue you might encounter during the voting period." data-position="right center" data-inverted=""> i </div>Open census registry</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Security</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Secure electronic voting systems, including nVotes, typically allow voters to change their cast ballot. This is a cohercion resistance mitigation security feature: if a cohercer has required you to vote in a specific way, later on you can change your ballot voting again during the voting period." data-position="right center" data-inverted=""> i </div>Voters can change their vote</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="All our plans include an extensive backup plan, and streaming database online replication in a master-slave configuration. We make sure it's very difficult to lose any of your data." data-position="right center" data-inverted=""> i </div>Backups and online-replication</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Cast-as-intended verifiability means that there's a procedure such that the voter can individually and privately verify that the ballot he generated expresses his/her intent. This is not an easy requirement to meet, as the ballot is encrypted and the decryption key is protected by the election authorities. Cast-as-intended verifiability is one of the security features needed to provide end-to-end verifiability. End-to-end verifiability is a requirement in all secure voting systems." data-position="right center" data-inverted=""> i </div>Cast-as-intended verifiability</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Recorded-as-cast verifiability means that there's some procedure for anyone to verify if a specific ballot is included in the tally, without revealing the voter's intent. Recorded-as-cast verifiability is one of the security features needed  to provide end-to-end verifiability. End-to-end verifiability is a requirement in all secure voting systems." data-position="right center" data-inverted=""> i </div>Recorded-as-cast verifiability</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Counted-as-recorded verifiability means that there's a procedure for anyone to verify that a ballot has been correctly tallied, without revealing the voter's intent. Counted-as-recorded is one of the security features needed  to provide end-to-end verifiability. End-to-end verifiability is a requirement in all secure voting systems." data-position="right center" data-inverted=""> i </div>Counted-as-recorded verifiability</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Providing a security contact is a practical and secure way for white hackers to report any vulnerability they might find. It is also usually presented with some do's and don't so that white-hackers can know what is ok and what is not, what is considered a security vulnerability and how to report it. The Hall of fame is a way to recognize these security researchers and their achievements. This is a desirable standard security measure generally welcomed by security researchers that reduces the risk of external unknown attacks, and this is why it is available in all plans." data-position="right center" data-inverted=""> i </div>Responsable disclosure policy, Security contact and Hall of fame</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Digitally signing the ballot by the ballot box server is a security feature that allows third-parties to verify that a ballot was cast. Usually the signed ballot includes a signed timestamp from a time server. This feature increases dispute-freeness, because the ballot box server cannot deny that a specific ballot was received and correctly cast. This is an advanced security feature. Please contact us if you are interested in ballot signing." data-position="right center" data-inverted=""> i </div>Ballot signed by server</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Digitally signing the ballot by the voter is a security feature that allows third-parties to verify eligibility of the voter and increases dispute-freeness, because a voter cannot deny that a specific ballot is his own if he signed it (non-repudiation), and on the other hand a ballot cannot be attributed to a third party if it's not signed. Note that having the ballot signed does not reveal the intention of the vote because the ballot remains encrypted. This is an advanced security feature only available for specific authentication methods. Please contact us if you are interested in ballot signing." data-position="right center" data-inverted=""> i </div>Ballot signed by voter</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="We can provide a generic threat model analysis for the Enterprise plan. However a specific threat model analysis is provided for clients using the Managed plan." data-position="right center" data-inverted=""> i </div>Dedicated threat model analysis</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Eligibility audit allows the election organizer to obtain statistical information about the level of census fraud. It consist in systematically contacting with a random subset of the electorate to check their eligibility and producing a statistical analysis of the generated data. This can be a powerful way to verify an open census or a census where the census data is not completely trusted. Please contact us if you are interested in this feature." data-position="right center" data-inverted=""> i </div>Eligibility audit</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="There are different methods to assess the security of a platform: conducting a security analysis of the source code, executing a penetration test or performing a vulnerability assessments are typical methods. Please contact us if you are interested in this kind of advanced security service." data-position="right center" data-inverted=""> i </div>Security audit, vulnerability assessment and penetration test</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="A bug bounty program establishes a set of rewards for white hackers that find security vulnerabilities. This is an industry standard and a cost-effective security measure. Please contact us if you  are interested in this kind of advanced security service." data-position="right center" data-inverted=""> i </div>Bug bounty program</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Transparency</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Some organizations require an official document that describes the electoral process. nVotes can produce such a document in electronic form (PDF)." data-position="right center" data-inverted=""> i </div>PDF election results certificate</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="An event registry is a log of all the events related to an election, and has proven to be an effective auditability and transparency measure. It's an advanced security feature only available in the Managed plan." data-position="right center" data-inverted=""> i </div>Public election events registry</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Independent election authorities perform a role similar to traditional paper ballot election scrutineers. They oversee the compliance with standard and previously agreed practices described in an Action Protocol." data-position="right center" data-inverted=""> i </div>Independent election authorities oversee action protocol compliance</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="A warrant canary is a practice whose aim is to inform that we have not been served with a secret government subpoena. If the canary has not been updated in the time period specified by the  host, users are to assume that the host has been served with such a subpoena. The intention is to allow the provider to warn users of the  existence of a subpoena passively, without disclosing to others that the  government has sought or obtained access to information or records under a secret subpoena." data-position="right center" data-inverted=""> i </div>Warrant canary</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Infrastructure</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="All our plans feature a load balancer on the server side. This means that we can perform updates without downtime, improves fault-tolerance and scales to the number of visitors." data-position="right center" data-inverted=""> i </div>High-Availability &amp; scalable load balancing setup</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Distributed Denial of Service (DDoS) attacks are the easiest to perform and are the most frequent. With a DDoS attackers try to overload servers with a high volume of requests per second. We use advanced mitigation techniques in collaboration with our partners to reduce the effectiveness of any potential attack." data-position="right center" data-inverted=""> i </div>Distributed Denial of Service (DDoS) attack mitigation</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="In the Free and Premium plans, nVotes servers use a shared, multi-tenant configuration. The Enterprise plan is configured with higher security and privacy settings, with dedicated configuration in virtual private servers." data-position="right center" data-inverted=""> i </div>Infrastructure</td>
        <td className="center aligned">shared</td>
        <td className="center aligned">dedicated</td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Price for 6 more months of infrastructure, server maintenance and security updates. This feature only applies to plans with dedicated infrastructure (the Enterprise plan)." data-position="right center" data-inverted=""> i </div>6 extra months of infrastructure maintenance</td>
        <td className="center aligned">n/a</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="The number of months of infrastructure and server maintenance and security updates included when contracting an election, counting from the initial deployment. This feature only applies to plans with dedicated infrastructure (the Enterprise plan)." data-position="right center" data-inverted=""> i </div>Months of infrastructure maintenance</td>
        <td className="center aligned">n/a</td>
        <td className="center aligned">2 months</td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Some organizations require the usage of their own infrastructure; sometimes they have their own datacenters. This is an advanced service that is available only in the Managed plan. Please contact us if you are interested." data-position="right center" data-inverted=""> i </div>Installation on your own infrastructure</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="A pre-production environment replicates the same infrastructure of the production environment to be able to perform tests before uploading any new feature into production. A dedicated pre-production environment for the customer is an advanced feature that doubles the infrastructure price and is only provided in the Managed plan." data-position="right center" data-inverted=""> i </div>Dedicated pre-production environment deployment</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Support</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Technical support driven by the community." data-position="right center" data-inverted=""> i </div>Community provided support</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Our support staff provides technical support via email and a ticket system. An issue tracker system lets us help you efficiently and in a timely manner. Support is provided in english during office hours (9AM to 5PM, GMT+1)." data-position="right center" data-inverted=""> i </div>Email support for election organizers</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Our support staff provides telephone technical support to lets us help you efficiently and in a timely manner. Support is provided in english  during office hours (9AM to 5PM, GMT+1)." data-position="right center" data-inverted=""> i </div>Telephone support for election organizers</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="nVotes email support for voters is usually provided by the organization running the election, the customer. If you want us provide this advanced feature, please contact us." data-position="right center" data-inverted=""> i </div>Email support for voters</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="nVotes telephone support for voters is usually provided by the organization running the election, the customer. If you want us provide this advanced feature, please contact us." data-position="right center" data-inverted=""> i </div>Telephone support for voters</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="nVotes chat support for voters is usually provided by the organization running the election, the customer. If you want us provide this advanced feature, please contact us." data-position="right center" data-inverted=""> i </div>Chat support for voters</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Customization</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Custom email messages</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Custom logo</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Custom colors and style sheets</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Custom domain or subdomain</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Custom sender-id in SMS</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Software customization is supported in the Enterprise plan, contact us for more details." data-position="right center" data-inverted=""> i </div>Software customization support</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Tally</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="nVotes ships with a broad list of multiple tally methods that are available to all plans. Among others: plurality at large, Borda Count, Dowdall Borda Count, Pairwise comparison." data-position="right center" data-inverted=""> i </div>Simple tally methods support</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="When the electoral process features both traditional paper ballots and online voting, it's described as a hybrid election. The most straight forward way to implement a hybrid election is to first run the online election and afterwards run the paper-ballot election. This method of hybrid elections is supported by all plans." data-position="right center" data-inverted=""> i </div>Hybrid elections support</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Procedural security measures are as important as technical security measures. Our experience has led us to incorporate the most important procedural security measures directly in our software. The most important of these kind of measures is the enforcement of running a mock election: this means that before running a real election, election organizers must create an election with the same configuration, cast at least one vote and tally the results. Because it is so important, this feature is included in all plans." data-position="right center" data-inverted=""> i </div>Mock election enforcement</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Although not available through the administration interface, more complex tally methods including different tie-break algorithms, parity ordering and candidate withdrawal are available in Enterprise plan." data-position="right center" data-inverted=""> i </div>Advanced tally methods</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="When the electoral process features both traditional paper ballots and online voting, it's described as a hybrid election. If you need to simultaneously run paper-ballot election and the electronic election, the way to implement it is using a single unified electronic identification and census management software. That way we can ensure that no voter casts more than one vote. Please contact us if you need this advanced feature." data-position="right center" data-inverted=""> i </div>Simultaneous hybrid election support having at the same time paper ballots and an online election</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="" data-position="right center" data-inverted=""> i </div>Custom tally method</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr className="plans-table-sectionHeader">
        <th colspan="3" className="sub-section">Services</th>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Procedural security measures are as important as technical security measures. Our experience has led us to incorporate the most important procedural security measures directly in our software. The most important of these kind of measures is the enforcement of running a mock election: this means that before running a real election, election organizers must create an election with the same configuration, cast at least one vote and tally the results. Because it is so important, this feature is included in all plans." data-position="right center" data-inverted=""> i </div>Mock election enforcement</td>
        <td><i className="green check icon"></i></td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="In the Enterprise plan an nVotes electoral processes expert will review and validate together with the customer a mock election to verify that everything is prepared for the real election to run without incidents." data-position="right center" data-inverted=""> i </div>Mock election validated by nVotes</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="In the Enterprise plan the customer will have the opportunity to arrange a skype conference call with our electoral processes experts of up to 2 hours to review their electoral process and answer their questions." data-position="right center" data-inverted=""> i </div>2 hours remote consultancy session</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="An Action Protocol defines normatively the technical procedures related to an election, both in normal and exceptional circumstances. Based on an nVotes template we'll work together with the client to generate an Action protocol that will be enforced by the election authorities. This is an advance procedural security measures only available in the Managed plan." data-position="right center" data-inverted=""> i </div>Definition of an Action Protocol</td>
        <td>&nbsp;</td>
        <td><i className="green check icon"></i></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="The Managed plan includes a Postmortem analysis and meeting between the different actors involved in the election. After an electoral process has been carried out, there's always room for improvement and it's a good moment to identify issues and improvements for the next process." data-position="right center" data-inverted=""> i </div>Postmortem analysis &amp; meeting</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        <tr>
        <td><div className="ui icon button" data-tooltip="Some of our clients are public institutions like cityhalls. Contracting with public bodies usually involve a specific bureaucratic process that varies depending on the applicable regulations. Please contact us if this is your case." data-position="right center" data-inverted=""> i </div>Contracting with a public corporation</td>
        <td>&nbsp;</td>
        <td><a href="https://nvotes.com/contact/">contact us</a></td>
        </tr>
        </tbody>
        </table>
      </div>
    </article>
  </Layout>

)
export default PlansPage
